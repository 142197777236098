<template lang="pug">
	div(class="imprint")
		div(class="row")
			div(class="col s12")
				separator(text="Oooops...")
		div(class="row")
			div(class="col s12")
				div(class="contentbox")
					p Diesen Inhalt konnten wir leider nicht finden :(
</template>

<script>
import Separator from '@/components/Separator';
export default {
	components: {
		'separator': Separator
	}
}
</script>